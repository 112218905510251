import React, { useEffect } from "react"
import { connect } from "react-redux"

const mapStateToProps = (state, ownProps) => ({
  parkboxMessages: state.messageReducer[ownProps["parkbox"]],
  showPingMessages: state.messageReducer.showPingMessages,
  blinkValue: state.messageReducer.blinkValue,
})

function WithSubscription(WrappedComponent) {
  return connect(mapStateToProps)(({ parkboxMessages, dispatch, ...props }) => {
    let messagesClone
    if (Array.isArray(parkboxMessages)) {
    messagesClone = [...parkboxMessages]
    messagesClone.reverse()
    }
    else {
      messagesClone = [] 
    }
    return (
      <>
        <WrappedComponent
          {...props}
          parkboxMessages={
            messagesClone
          }
          showPingMessages={props.showPingMessages}
          pingValue={props.blinkValue}
        />
      </>
    )
  })
}

export default WithSubscription
