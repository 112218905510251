import React from "react"
import logo from "./logo.svg"
import "./App.css"
import AppRenderer from "./AppRenderer"
import SignIn from "./components/Login/SignIn"
import "react-toastify/dist/ReactToastify.css"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"
import { ToastContainer } from "react-toastify"

function AuthRoute({ component: Component, ...rest }) {
  let auth = false
  let localStorageToken = localStorage.getItem("token")
  let sessionStorageToken = sessionStorage.getItem("token")
  if (localStorageToken || sessionStorageToken) {
    auth = true
  }
  return (
    <Route
      render={(props) =>
        auth ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  )
}

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <AuthRoute path="/" exact component={AppRenderer} />
          <Route path="/login" render={(props) => <SignIn />} />
        </Switch>
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default App
