import React from "react"
import MqttWrapper from "./components/mqtt"
import createMqttClientContext from "./Contexts/mqttClientContext/mqttClientContext"
import { client } from "./Contexts/mqttClientContext/mqttClientContext"
import { Provider } from "react-redux"
import store from "./redux/store"

const MqttContext = createMqttClientContext()

const AppRenderer = () => {
  return (
    <MqttContext.Provider value={client}>
      <Provider store={store}>
        <MqttWrapper />
      </Provider>
    </MqttContext.Provider>
  )
}

export default AppRenderer
