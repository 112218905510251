import axios from "axios"
import { toast } from "react-toastify"
import baseUrl from "../apiList"

const authAxios = axios.create({
  baseURL: baseUrl,
})

authAxios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    let localStorageToken = localStorage.getItem("token")
    let sessionStorageToken = sessionStorage.getItem("token")

    let token = localStorageToken || sessionStorageToken
    config.headers.Authorization = `Token ${token}`
    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)

authAxios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function (error) {
    toast.error("error occured in fetching data")
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error)
  }
)

export default authAxios
