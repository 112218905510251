import React, { useEffect } from "react"
import Button from "@material-ui/core/Button"
import { useMqttClientContext } from "../../Contexts/mqttClientContext/mqttClientContext"

const RestartParkboxes = ({ parkboxOptions }) => {
  const client = useMqttClientContext()

  useEffect(() => {
    console.log(parkboxOptions, "parkboxOptions")
  }, [parkboxOptions])

  const handleRestartParkboxes = (parkboxOptions) => {
    for (let parkbox of parkboxOptions) {
      client.publish(
        parkbox.parkbox_mac_address,
        JSON.stringify({
          Data_type: "Parkbox",
          Data: "RESTART",
        })
      )
    }
  }

  return (
    <div>
      <h3 style={{ padding: 10 }}>Parkbox to restarted -</h3>
      <ul>
        {parkboxOptions.map((parkbox) => {
          return <li>{parkbox.parkbox_mac_address}</li>
        })}
      </ul>

      <div style={{ textAlign: "right", padding: 20 }}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleRestartParkboxes(parkboxOptions)}
        >
          Restart All
        </Button>
      </div>
    </div>
  )
}

export default RestartParkboxes
