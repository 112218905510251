import ConnectionStatus from "./ConnectionStatus/ConnectionStatus"
import ZeroVariableCommand from "./CommandsComponents/ZeroVariableCommand"
import SetPowerLevel from "./CommandsComponents/SetPowerLevel"
import CheckIssuerBankKeyAvailability from "./CommandsComponents/CheckIssuerBankKeyAvailability"
import SetScanTime from "./CommandsComponents/setScanTime"
import SetLogTagAndLevel from "./CommandsComponents/SetLogTagAndLevel"
import CustomCommandsInput from "./CommandsComponents/CustomCommandInput"
import RestartParkboxes from "./RestartParkboxes/RestartParkboxes"
import CheckTagStatusOnBasisOfSitepass from './CommandsComponents/CheckTagStatusOnBasisOfSitePass';
const NON_VARYING = "NON_VARYING"
const VARYING = "VARYING"

// These commands do not take
const commands = [
  {
    /* This needs to be first element other wise things will break */
    label: "Restart All Parkboxes",
    component: RestartParkboxes,
    commandType: VARYING,
  },
  {
    label: "Custom Command",
    component: CustomCommandsInput,
    commandType: VARYING,
  },
  {
    label: "Get Version",
    command: '{"Data_type":"Parkbox","Data":"VERSION"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Restart Parkbox",
    command: '{"Data_type":"Parkbox","Data":"RESTART"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Push OTA",
    command: '{"Data_type":"Parkbox","Data":"OTA"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Get RAM",
    command: '{"Data_type":"Parkbox","Data":"RAM"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Show Tasklist",
    command: '{"Data_type":"Parkbox","Data":"TASKLIST"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Task System State",
    command: '{"Data_type":"Parkbox","Data":"TASK_SYSTEM_STATE"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Start Receiving Logs",
    command: '{"Data_type":"Parkbox","Data":"REMOTE_LOG_START"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Stop Receiving Logs",
    command: '{"Data_type":"Parkbox","Data":"REMOTE_LOG_END"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Set Power Level",
    component: SetPowerLevel,
    commandType: VARYING,
  },
  {
    label: "Check Issuer Bank Key Availability",
    component: CheckIssuerBankKeyAvailability,
    commandType: VARYING,
  },
  {
    label: "Set Scan Time",
    component: SetScanTime,
  },
  {
    label: "Set Log Level And Log Tag",
    component: SetLogTagAndLevel,
  },
  {
    label: "Get Current Power",
    command: '{"Data_type":"Parkbox","Data":"GET_CURRENT_POWER"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Get Site Info",
    command: '{"Data_type":"Parkbox","Data":"GET_SITE_INFO"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Get Client IP",
    command: '{"Data_type":"Parkbox","Data":"GET_CLIENT_IP"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Get Current Time",
    command: '{"Data_type":"Parkbox","Data":"GET_CURRENT_TIME"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Get Current Temperature",
    command: '{"Data_type":"Parkbox","Data":"GET_CURRENT_TEMPERATURE"}',
    component: ZeroVariableCommand,
    commandType: NON_VARYING,
  },
  {
    label: "Check Tag Status On Basis Of Sitepass",
    component: CheckTagStatusOnBasisOfSitepass,
    commandType: VARYING,
  }
]

const GET_RAM_COMMAND = { Data_type: "Parkbox", Data: "RAM" }
const GET_VERSION_COMMAND = { Data_type: "Parkbox", Data: "VERSION" }

export { commands, NON_VARYING, VARYING, GET_RAM_COMMAND, GET_VERSION_COMMAND }
