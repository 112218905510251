import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import JSONPretty from "react-json-pretty"
import Box from "@material-ui/core/Box"
import { useMqttClientContext } from "../../Contexts/mqttClientContext/mqttClientContext"
import Button from "@material-ui/core/Button"

/* 
These commands do not have any parameters, they have static data to be sent to parkbox 
every time. 

for eg - To fetch Ram data is - 
{
  "Data_type": "Parkbox",
  "Data": "RAM"
}

This command do not have any varying parameter. as 
Data_type will alwasys be Parkbox and Data will be RAM always.
*/

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 500,
    display: "flex",
    flexDirection: "column",
    padding: 24,
    boxSizing: "border-box",
    overflow: "hidden",
  },
}))

const ZeroVariableCommand = ({ topic, command }) => {
  let classes = useStyles()
  const client = useMqttClientContext()
  const handleSend = () => {
    client.publish(topic, command, {}, function (err) {
      if (err) {
        alert(err)
      }
    })
  }

function RenderJson(props){
  const jsonData = props.jsonCommand;
  try {
    return <JSONPretty id="json-pretty" data={JSON.parse(jsonData)}></JSONPretty>
  } catch(error){
    console.log(error)
  }
  return <pre>{JSON.stringify(jsonData, null, 1) }</pre>
  // return '{“Data_type”:“Parkbox”,“Data”:“TASKLIST”}'
}

  return (
    <Box className={classes.root}>
      <pre>{topic && topic}</pre>
      <div style={{ flexGrow: 1 }}>
        {/* json.parse, exception needs to be handled */}
        {/* <JSONPretty id="json-pretty" data={JSON.parse(command)}></JSONPretty> */}
        <RenderJson jsonCommand={command} />
      </div>
      <div style={{ textAlign: "right", padding: 20 }}>
        <Button onClick={handleSend} color="primary" variant="contained">
          send
        </Button>
      </div>
    </Box>
  )
}

export default ZeroVariableCommand
