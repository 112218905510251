import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import JSONPretty from "react-json-pretty"
import Box from "@material-ui/core/Box"
import { useMqttClientContext } from "../../Contexts/mqttClientContext/mqttClientContext"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"

/* 
These commands do have any parameters, they have data to be sent to parkbox 
that can vary. 

S_POWER Range is 0-30
Set Power: { "Data_type":"Parkbox", "Data":"POWER", "S_POWER": 25 }

Here S_POWER parameter is variable and user may change this. 
*/

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 500,
    display: "flex",
    flexDirection: "column",
    padding: 24,
    boxSizing: "border-box",
    overflow: "hidden",
  },
}))

let powerLevelOptions = []

for (let i = 0; i <= 30; i++) {
  powerLevelOptions.push(String(i))
}

function generateCommandJson(powerLevel) {
  if (!powerLevel) {
    powerLevel = 0
  }
  return { Data_type: "Parkbox", Data: "POWER", S_POWER: Number(powerLevel) }
}

const SetPowerLevel = ({ topic }) => {
  let classes = useStyles()
  const client = useMqttClientContext()
  const [powerLevel, setPowerLevel] = useState(25)
  const handleSend = (powerLevel) => {
    let message = JSON.stringify(generateCommandJson(powerLevel))
    client.publish(topic, message, {}, function (err) {
      if (err) {
        alert(err)
      }
    })
  }

  return (
    <Box className={classes.root}>
      <pre>{topic}</pre>
      <div>
        {/* json.parse, exception needs to be handled */}
        <JSONPretty
          id="json-pretty"
          data={generateCommandJson(powerLevel)}
        ></JSONPretty>
      </div>
      <div style={{ flexGrow: 1 }}>
        <Autocomplete
          options={powerLevelOptions}
          getOptionLabel={(option) => String(option)}
          style={{ width: 200 }}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              label="Power Level"
              variant="outlined"
            />
          )}
          value={powerLevel}
          onChange={(event, value) => {
            setPowerLevel(value)
          }}
          disableClearable
        />
      </div>
      <div style={{ textAlign: "right", padding: 20 }}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleSend(powerLevel)}
        >
          send
        </Button>
      </div>
    </Box>
  )
}

export default SetPowerLevel
