import React from "react"
import styled from "styled-components"

const FlickerText = styled.div`
  float: right;
  margin: 0;
  width: 24px;
  height: 24px;
  background-color: #a00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
    rgba(255, 0, 0, 0.5) 0 2px 12px;
  -webkit-animation: blinkRed 0.5s;
  -moz-animation: blinkRed 0.5s;
  -ms-animation: blinkRed 0.5s;
  -o-animation: blinkRed 0.5s;
  animation: blinkRed 0.5s;

  @-webkit-keyframes blinkRed {
    from {
      background-color: #f00;
    }
    50% {
      background-color: #a00;
      box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
        rgba(255, 0, 0, 0.5) 0 2px 0;
    }
    to {
      background-color: #f00;
    }
  }
  @-moz-keyframes blinkRed {
    from {
      background-color: #f00;
    }
    50% {
      background-color: #a00;
      box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
        rgba(255, 0, 0, 0.5) 0 2px 0;
    }
    to {
      background-color: #f00;
    }
  }
  @-ms-keyframes blinkRed {
    from {
      background-color: #f00;
    }
    50% {
      background-color: #a00;
      box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
        rgba(255, 0, 0, 0.5) 0 2px 0;
    }
    to {
      background-color: #f00;
    }
  }
  @-o-keyframes blinkRed {
    from {
      background-color: #f00;
    }
    50% {
      background-color: #a00;
      box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
        rgba(255, 0, 0, 0.5) 0 2px 0;
    }
    to {
      background-color: #f00;
    }
  }
  @keyframes blinkRed {
    from {
      background-color: #f00;
    }
    50% {
      background-color: #a00;
      box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
        rgba(255, 0, 0, 0.5) 0 2px 0;
    }
    to {
      background-color: #f00;
    }
  }
`

const Blinker = ({ blinkValue }) => {
  return (
    <>
      {blinkValue ? (
        blinkValue === 1 ? (
          <FlickerText key={blinkValue} />
        ) : (
          <FlickerText key={blinkValue} />
        )
      ) : null}
    </>
  )
}

export default Blinker
