import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import JSONPretty from "react-json-pretty"
import Box from "@material-ui/core/Box"
import { useMqttClientContext } from "../../Contexts/mqttClientContext/mqttClientContext"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"

/* 
These commands do have any parameters, they have data to be sent to parkbox 
that can vary. 


Set scan time: { "Data_type":"Parkbox", "Data":"SCAN_TIME", "S_TIME": 25 }
Here S_TIME parameter is variable and user may change this. 
*/

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 500,
    display: "flex",
    flexDirection: "column",
    padding: 24,
    boxSizing: "border-box",
    overflow: "hidden",
  },
}))

let scanTimeOptions = []

for (let i = 3; i <= 255; i++) {
  scanTimeOptions.push(i)
}

function generateCommandJson(scanTime) {
  //   if (!powerLevel) {
  //     powerLevel = 0
  //   }
  return { Data_type: "Parkbox", Data: "POWER", S_TIME: Number(scanTime) }
}

const SetScanTime = ({ topic }) => {
  let classes = useStyles()
  const client = useMqttClientContext()
  const [scanTime, setScanTime] = useState(25)
  const handleSend = (scanTime) => {
    let commandJSON = generateCommandJson(scanTime)
    client.publish(topic, JSON.stringify(commandJSON), {}, function (err) {
      if (err) {
        alert(err)
      }
    })
  }

  return (
    <Box className={classes.root}>
      <pre>{topic}</pre>
      <div>
        {/* json.parse, exception needs to be handled */}
        <JSONPretty
          id="json-pretty"
          data={generateCommandJson(scanTime)}
        ></JSONPretty>
      </div>
      <div style={{ flexGrow: 1 }}>
        <Autocomplete
          options={scanTimeOptions}
          getOptionLabel={(option) => String(option)}
          style={{ width: 200 }}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              label="Scan Time"
              variant="outlined"
            />
          )}
          value={scanTime}
          onChange={(event, value) => {
            setScanTime(value)
          }}
          disableClearable
        />
      </div>
      <div style={{ textAlign: "right", padding: 20 }}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleSend(scanTime)}
        >
          send
        </Button>
      </div>
    </Box>
  )
}

export default SetScanTime
