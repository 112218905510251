import React, { useState } from "react"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import { commands, NON_VARYING, VARYING } from "../constants"
import { makeStyles } from "@material-ui/core/styles"

const useStylesBox = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 500,
    display: "flex",
    flexDirection: "column",
    padding: 24,
    boxSizing: "border-box",
    overflow: "hidden",
  },
}))

function renderComponent(Component, props) {
  if (!Component) return
  return <Component {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: 500,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: 200,
    minWidth: 200,
    maxWidth: 200,
  },
}))

const VerticalTabs = ({ parkbox, parkboxOptions, ...props }) => {
  const classes = useStyles()
  const [selectedIndex, setIndex] = useState(0)
  return (
    <div className={classes.root}>
      {console.log(props, "of tabs")}
      <Tabs
        orientation="vertical"
        value={selectedIndex}
        onChange={(e, index) => setIndex(index)}
        className={classes.tabs}
        variant="scrollable"
      >
        {commands.map((item, index) => (
          <Tab label={item.label} key={item.label} value={index} />
        ))}
      </Tabs>
      {parkbox || selectedIndex === 0
        ? renderComponent(commands[selectedIndex]["component"], {
            topic: parkbox,
            command: commands[selectedIndex]["command"],
            parkboxOptions: parkboxOptions,
          })
        : null}

      {/* <TabPanel
        topic={props.parkbox}
        command={commands[value].command}
      ></TabPanel> */}
    </div>
  )
}

export default VerticalTabs
