let baseUrl
let httpsprefix = "https://"
let httpprefix = "https://"

const productionHost = "mqtt-dashboard.parkzap.com"
const testingHost = "live23.parkzap.com"
const testMqttHost = "live23.parkzap.com"
const localhost = "localhost"

let host = window.location.hostname
host = host.toLowerCase()

switch (host) {
  case productionHost:
    baseUrl = httpsprefix + "live23.parkzap.com"
    break
  case testMqttHost:
    baseUrl = httpsprefix + testingHost
    break
  case localhost:
    baseUrl = httpsprefix + testingHost
    break
  default:
    baseUrl = httpprefix + testingHost
    break
}

export default baseUrl
