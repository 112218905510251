import React, { useEffect, useState, useRef } from "react"
import { useMqttClientContext } from "../Contexts/mqttClientContext/mqttClientContext"
import WithSubscription from "./HOCs/WithSubscription"
import { GET_VERSION_COMMAND, GET_RAM_COMMAND } from "./constants"
import Blinker from "./Blinker/blinker"

const formatMessage = (message) => {
  let formattedMessage
  try {
    formattedMessage = JSON.stringify(JSON.parse(message))
  } catch (error) {
    // formattedMessage = message.split(/\n|\s/g).join("")
    formattedMessage = message.split(/\n/g).join("")
  }

  // to change the color of the Information tags 
  if (['I', 'E', 'W', 'D'].includes(formattedMessage[0])) {
    switch (formattedMessage[0]) {
      case 'I':
        return (<>
          <span style={{ color: 'green' }}>
            {formattedMessage}
          </span>
        </>)
      case 'W':
        return (<>
          <span style={{ color: 'yellow' }}>
            {formattedMessage}
          </span>
          {/* {formattedMessage.substring(1, formattedMessage.length)} */}
        </>)
      case 'E':
        return (<>
          <span style={{ color: 'red' }}>
            {formattedMessage}
          </span>
          {/* {formattedMessage.substring(1, formattedMessage.length)} */}
        </>)
      case 'D':
        return (
          <>
          <span style={{ color: 'gray' }}>
            {formattedMessage}
          </span>
          {/* {formattedMessage.substring(1, formattedMessage.length)} */}
        </>
       )
    default:
      return formattedMessage
    }
  }

  return formattedMessage
}

const Logger = (props) => {
  const client = useMqttClientContext()
  const ref = useRef(null)

  const [messages, setMessages] = useState([])
  // To check if user has scrolled or not. only set to true if user has
  // scrolled somewhere else other than bottom
  const [userScroll, setUserScroll] = useState(null)

  useEffect(() => {
    client.subscribe(props.parkbox, (err) => {
      if (!err) {
        client.publish(props.parkbox, JSON.stringify(GET_VERSION_COMMAND))
        client.publish(props.parkbox, JSON.stringify(GET_RAM_COMMAND))
      }
    })

    return () => {
      client.unsubscribe(props.parkbox)
    }
  }, [props.parkbox])

  // to debug log the length of the messages received
  useEffect(() => {
    if (Array.isArray(props.parkboxMessages)) {
      console.log(props.parkboxMessages)
    }
  }, [props.parkboxMessages])
  // useEffect(() => {
  //   const ContainerDiv = ref.current

  //   const listener = (event) => {
  //     if (ContainerDiv.target.scrollHeight !== ContainerDiv.target.scrollTop) {
  //       setUserScroll(true)
  //     } else {
  //       setUserScroll(false)
  //     }
  //   }

  //   ContainerDiv.addEventListener("scroll", listener)
  //   return () => ContainerDiv.removeEventListener("scroll", listener)
  // }, [])

  // useEffect(() => {
  //   const ContainerDiv = ref.current
  //   if (userScroll) return
  //   console.log(userScroll)
  //   ContainerDiv.scrollTop = ContainerDiv.scrollHeight
  // }, [props])

  return (
    <div
      style={{
        backgroundColor: "#272c34",
        height: 500,
        borderRadius: 4,
        margin: 5,
        padding: 24,
        overflowY: "auto",
      }}
      ref={ref}
    >
      <div
        style={{
          textTransform: "uppercase",
          color: "white",
          fontSize: "smaller",
          textAlign: "left",
          margin: "0 auto 10px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        logs for parkbox - {props.parkbox} -{" "}
        <Blinker blinkValue={props.blinkValue} />
      </div>
      {props.parkboxMessages.map((message, index) => {
        if (message.isPingMessage && !props.showPingMessages) return null
        return (
          <div
            style={{ textAlign: "left", overflowWrap: "break-word" }}
            key={message.key}
          >
            <b style={{ color: "green" }}>→</b>
            <div
              style={{
                color: "white",
                display: "inline",
                overflowWrap: "break-word",
                fontFamily: "monospace",
              }}
            >
              {formatMessage(message.message)}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default WithSubscription(Logger)
