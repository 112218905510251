import React, { useState, useEffect } from "react"
import { useMqttClientContext } from "../../Contexts/mqttClientContext/mqttClientContext"
import styled from "styled-components"

const ColoredCircle = styled.div`
  height: 15px;
  width: 15px;
  background: ${(props) => (props.connected ? "#4CAF50" : "#F44336")};
  border-radius: 50%;
  margin: 2px;
`

const ConnectionStatus = () => {
  const [status, setStatus] = useState(false)
  const client = useMqttClientContext()
  useEffect(() => {
    setInterval(() => {
      if (client.connected) {
        setStatus(true)
      } else {
        setStatus(false)
      }
    }, 1000)
  }, [])
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <ColoredCircle connected={status} />
      <div style={{ width: 100, textAlign: "left" }}>
        {status ? "Connected" : "Disconnected"}
      </div>
    </div>
  )
}

export default React.memo(ConnectionStatus)
