const PUSH_MESSAGE = "PUSH_MESSAGE"
const CLEAR_HISTORY = "CLEAR_HISTORY"
const ENABLE_DIABLE_PING_MESSAGE = "ENABLE_DISABLE_PING_MESSAGE"

function pushMessage(payload) {
  return { type: PUSH_MESSAGE, topic: payload.topic, message: payload.message }
}

function clearHistory() {
  return { type: CLEAR_HISTORY }
}

function enableDisablePingMessage(enabled) {
  return { type: ENABLE_DIABLE_PING_MESSAGE, enabled }
}

export {
  PUSH_MESSAGE,
  clearHistory,
  CLEAR_HISTORY,
  enableDisablePingMessage,
  ENABLE_DIABLE_PING_MESSAGE,
}

export default pushMessage
