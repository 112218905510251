import React from "react"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import MuiAvatar from "./MuiAvatar"
import { useHistory } from "react-router-dom"

export default function UserAvtar() {
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleLogout = () => {
    // this is being called on onChange event of menu.
    // this is working right now but will behave differently if
    // we add another menuitem here. then we will have to look for particular selected index
    // and if it is logout only then we can run below statements otherwise just return from here.
    localStorage.removeItem("token")
    sessionStorage.removeItem("token")
    history.push("/login")
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <button
        onClick={handleClick}
        style={{
          backgroundColor: "transparent",
          border: "none",
        }}
      >
        <MuiAvatar
          aria-controls="simple-menu"
          aria-haspopup="true"
          src="/broken-image.jpg"
        />
      </button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onChange={handleLogout}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  )
}
