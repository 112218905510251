import React, { useContext } from "react"
import { connect } from "mqtt"
import store from "../../redux/store"
import pushMessage from "../../redux/mqttMessages/MessageActions"

const client = connect("mqtt://mqtt-parbox.parkzap.com", {
  protocol: "wss",
  port: 443,
  path: "/mqtt",
})

/* Redux related things */
client.on("message", (topic, binaryMessage) => {
  let message = binaryMessage.toString()
  console.log(message.substring(1, message.length - 1))
  try {
    let messageJson = JSON.parse(message.substring(1, message.length - 2))
    if (messageJson["DATA_TYPE"] === 'LOG') {
      let dataToBePrintend = messageJson["DATA"]
      let firstSplitArray = dataToBePrintend.split('(')
      let tag = firstSplitArray[0][firstSplitArray[0].length - 2]
      let secondSlpitArray = firstSplitArray[1].split(')')
      let timeStamp = secondSlpitArray[0]
      let thirdSplit = secondSlpitArray[1].split('[')
      let usefulLogData = thirdSplit[0].substring(0, thirdSplit[0].length - 1)
      console.log(tag, timeStamp, usefulLogData)
      message = `${tag} ${timeStamp} ${usefulLogData}`
    }
  }
  catch (error) {
    console.log(error)
  }
  try {
    store.dispatch(pushMessage({ topic, message }))
  } catch (error) {
    console.log(error, binaryMessage.toString())
  }
})

let saveStateToLocalStorageWithDebounce = debounce(
  (storeAsString) => localStorage.setItem("mqttHistory", storeAsString),
  3000
)

// store.subscribe((a) => {
//   saveStateToLocalStorageWithDebounce(JSON.stringify(store.getState()))
// })

function debounce(callback, delay) {
  let timer
  return (stateAsString) => {
    clearTimeout(timer)
    timer = setTimeout(() => callback(stateAsString), delay)
  }
}

/* Redux related things - closing */

const mqttClientContext = React.createContext()
export default function createMqttClientContext() {
  return mqttClientContext
}

function useMqttClientContext() {
  return useContext(mqttClientContext)
}

export { useMqttClientContext, client }
