import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import ConnectionStatus from "../ConnectionStatus/ConnectionStatus"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import axios from "axios"
import authAxios from "../../axiosConfigured/axiosConfigured"
import { clearHistory } from "../../redux/mqttMessages/MessageActions.js"
import { connect } from "react-redux"
import { GiBroom } from "react-icons/gi"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import UserAvtar from "./UserAvatar"
import EnableDisablePing from "../EnableDisablePing/EnableDisablePing"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const Toolbar = ({ parkboxOptions, setParkboxOptions, ...props }) => {
  const { parkbox, setParkbox } = props
  const classes = useStyles()
  const [siteOptions, setSiteOptions] = useState([])
  const [site, setSite] = useState(null)
  const [gateOptions, setGateOptions] = useState([])
  const [gate, setGate] = useState(null)

  const handleChange = (event) => {
    setSite(event.target.value)
  }

  useEffect(() => {
    authAxios
      .get("/parkzap-pos/get_aoi_site_list/")
      .then((res) => {
        setSiteOptions(res.data)
      })
      .catch((err) => {
        console.log(err.response)
      })
  }, [])

  useEffect(() => {
    setGate(null)
    setGateOptions([])
    if (site) {
      authAxios
        .post("/parkzap-pos/get_gate_list/", { aoi_site: site.id })
        .then((res) => {
          setGateOptions(res.data)
        })
        .catch((err) => {
          console.log(err.response)
        })
    }
  }, [site])

  useEffect(() => {
    setParkbox(null)
    setParkboxOptions([])
    if (gate) {
      authAxios
        .post("/parkzap/parkbox/get_parkbox_list/", { gate: gate.id })
        .then((res) => {
          setParkboxOptions(res.data)
        })
        .catch((err) => {
          console.log(err.response)
        })
    }
  }, [gate])

  return (
    <div
      style={{
        marginLeft: 29,
        display: "flex",
        alignItems: "center",
      }}
    >
      {console.log(parkboxOptions, parkbox)}
      <div style={{ flexGrow: 1 }}>
        <FormControl className={classes.formControl}>
          <Autocomplete
            size={"small"}
            options={siteOptions}
            getOptionLabel={(option) => option.name}
            style={{ width: 200 }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                label="Site"
                variant="outlined"
              />
            )}
            value={site}
            onChange={(event, value) => {
              setSite(value)
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <Autocomplete
            size={"small"}
            options={gateOptions}
            getOptionLabel={(option) => option.name}
            style={{ width: 200 }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                label="Gate"
                variant="outlined"
              />
            )}
            value={gate}
            onChange={(event, value) => {
              setGate(value)
            }}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <Autocomplete
            size={"small"}
            options={parkboxOptions}
            getOptionLabel={(option) => option.parkbox_mac_address}
            style={{ width: 200 }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                label="Parkbox"
                variant="outlined"
              />
            )}
            value={parkbox}
            onChange={(event, value) => {
              setParkbox(value)
            }}
          />
        </FormControl>
      </div>
      <div style={{ margin: 5 }}>
        <ConnectionStatus />
      </div>
      <div>
        <EnableDisablePing />
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="clear history">
          <IconButton onClick={props.clearHistory}>
            <GiBroom
              aria-label="clear history"
              aria-aria-labelledby="clear history"
            />
          </IconButton>
        </Tooltip>
      </div>
      <div>
        <UserAvtar />
      </div>
    </div>
  )
}

const mapDispatchToProps = { clearHistory }

export default connect(null, mapDispatchToProps)(Toolbar)
