import React, { useEffect, useState } from "react"
import { subscribe } from "react-mqtt-client"
import styled from "styled-components"
import Nav from "./nav"
import Logger from "./Logger"
import { connect } from "mqtt"
import { useMqttClientContext } from "../Contexts/mqttClientContext/mqttClientContext"
import ConnectionStatus from "./ConnectionStatus/ConnectionStatus"
import VerticalTabs from "./TabPanel/Tabs"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Toolbar from "./Toolbar/Toolbar"

const Container = styled.div`
  padding-top: 10px;
`

const Row = styled.div`
  height: 100vh;
`

const LogContainer = styled.div`
  min-height: 100%;
  background: black;
`

const CommandsContainer = styled.div``

const Panel = (props) => {
  const client = useMqttClientContext()
  const [parkboxOptions, setParkboxOptions] = useState([])
  // const [parkbox, setParkbox] = useState({
  //   id: 1,
  //   parkbox_mac_address: "24:0a:c4:25:9e:a7",
  // })
  const [parkbox, setParkbox] = useState(null)

  try {
    client.subscribe("hi", function (err) {
      if (err) {
        console.log(err)
        return
      }
    })
  } catch (error) {
    console.log(error)
  }

  return (
    <>
      {/* <Nav /> */}
      {/* <CommandsContainer className="col-md-4">
        <ConnectionStatus />
        <div>
          <Button
            variant={parkbox === "p1" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setParkbox("p1")}
            style={{ display: "inline", margin: 5, marginLeft: 150 }}
          >
            P1
          </Button>
          <Button
            variant={parkbox === "p2" ? "contained" : "outlined"}
            color="primary"
            onClick={() => setParkbox("p2")}
          >
            P2
          </Button>
        </div>
      </CommandsContainer> */}
      <Toolbar
        setParkbox={setParkbox}
        parkboxOptions={parkboxOptions}
        setParkboxOptions={setParkboxOptions}
        parkbox = {parkbox}
      />
      <Grid container spacing={0}>
        <Grid item xs={5}>
          <div
            style={{
              padding: 24,
              backgroundColor: "#f5f5f5",
              borderRadius: 4,
              margin: 5,
            }}
          >
            {/* need to lift parkbox options state up in this component from toolbar component 
            and pass it down to tabs */}
            <VerticalTabs
              parkbox={parkbox && parkbox.parkbox_mac_address}
              parkboxOptions={parkboxOptions}
            />
          </div>
        </Grid>
        <Grid item xs={7}>
          {parkbox ? <Logger parkbox={parkbox.parkbox_mac_address} /> : null}
        </Grid>
      </Grid>
      {/* <Row className="row"></Row> */}
    </>
  )
}

export default Panel
