import {
  PUSH_MESSAGE,
  CLEAR_HISTORY,
  ENABLE_DIABLE_PING_MESSAGE,
} from "./MessageActions"
import { weakKey, getBlinkValue } from "./utils"

/* 
should be in the form 
{
    "mac:address:1" : ['message1', 'message2']
} 
*/
const initialState = { showPingMessages: false, blinkValue: 0 }

function messageReducer(state = initialState, action) {
  switch (action.type) {
    case PUSH_MESSAGE:
      let currentActionTopicMessages = state[action.topic] // array of messages
      // see if the message is pingMessage or not.
      let isPingMessage = false
      // let blink value
      let blinkValue = 0
      try {
        let pingRegex = new RegExp("PING_STATUS")
        if (pingRegex.test(action.message)) {
          isPingMessage = true
          blinkValue = getBlinkValue(state.blinkValue)
        }
      } catch (err) {
        console.log(err)
      }

      let currentActionTopicMessagesClone // clone of array of messages for current actions topic
      // if current actions topic messages already exists
      if (currentActionTopicMessages) {
        // check if the messages field is array only
        if (Array.isArray(currentActionTopicMessages)) {
          // if it is array than make a clone of that array
          currentActionTopicMessagesClone = Array.from(
            currentActionTopicMessages
          )
          // push the actions message in that array
          currentActionTopicMessagesClone.push({
            key: weakKey(action.message),
            message: action.message,
            isPingMessage,
          })
        } else {
          // if it is not array that it is an error, though this condition should never arise.
          // log error here to sentry
          // then push the current actions message in a new array
          currentActionTopicMessagesClone = [
            {
              key: weakKey(action.message),
              message: action.message,
              isPingMessage,
            },
          ]
        }
      } else {
        // current actions topic messages do not already exist then please
        // create an array and put it against topic key like -> {topic: [message]}
        currentActionTopicMessagesClone = [
          {
            key: weakKey(action.message),
            message: action.message,
            isPingMessage,
          },
        ]
      }
      if (
        Array.isArray(currentActionTopicMessagesClone) &&
        currentActionTopicMessagesClone.length > 300
      ) {
        currentActionTopicMessagesClone.shift()
      }

      return {
        ...state,
        [action.topic]: currentActionTopicMessagesClone,
        blinkValue: blinkValue ? blinkValue : 0,
      }
    case CLEAR_HISTORY:
      return initialState
    case ENABLE_DIABLE_PING_MESSAGE:
      return { ...state, showPingMessages: action.enabled }
    default:
      return state
  }
}

export default messageReducer
