import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import JSONPretty from "react-json-pretty"
import Box from "@material-ui/core/Box"
import { useMqttClientContext } from "../../Contexts/mqttClientContext/mqttClientContext"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { logTagsOptions, logLevelOptions, ESP_LOGI } from "./logConstants"

/* 
These commands do have any parameters, they have data to be sent to parkbox 
that can vary. 


Set scan time: { "Data_type":"Parkbox", "Data":"SCAN_TIME", "S_TIME": 25 }
Here S_TIME parameter is variable and user may change this. 
*/

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 500,
    display: "flex",
    flexDirection: "column",
    padding: 24,
    boxSizing: "border-box",
    overflow: "hidden",
  },
}))

function generateCommandJson(logLevel, logTag) {
  //   if (!powerLevel) {
  //     powerLevel = 0
  //   }
  return {
    Data_type: "Parkbox",
    Data: "LOG_LEVEL_SET",
    LOG_TAG: logTag.value,
    LOG_LEVEL: logLevel.value,
  }
}

const SetLogTagAndLevel = ({ topic }) => {
  let classes = useStyles()
  const client = useMqttClientContext()
  const [logLevel, setLogLevel] = useState(logLevelOptions[0])
  const [logTag, setLogTag] = useState(logTagsOptions[0])
  const handleSend = (scanTime) => {
    let commandJSON = generateCommandJson(logLevel, logTag)
    client.publish(topic, JSON.stringify(commandJSON), {}, function (err) {
      if (err) {
        alert(err)
      }
    })
  }

  return (
    <Box className={classes.root}>
      <pre>{topic}</pre>
      <div>
        {/* json.parse, exception needs to be handled */}
        <JSONPretty
          id="json-pretty"
          data={generateCommandJson(logLevel, logTag)}
        ></JSONPretty>
      </div>
      <div style={{ flexGrow: 1 }}>
        <Autocomplete
          options={logLevelOptions}
          getOptionLabel={(option) => option.title}
          style={{ width: 200 }}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              label="Log Level"
              variant="outlined"
            />
          )}
          value={logLevel}
          onChange={(event, value) => {
            setLogLevel(value)
          }}
          disableClearable
        />
        <Autocomplete
          options={logTagsOptions}
          getOptionLabel={(option) => option.title}
          style={{ width: 200 }}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="normal"
              label="Log Tag"
              variant="outlined"
            />
          )}
          value={logTag}
          onChange={(event, value) => {
            setLogTag(value)
          }}
          disableClearable
        />
      </div>
      <div style={{ textAlign: "right", padding: 20 }}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleSend(logLevel, logTag)}
        >
          send
        </Button>
      </div>
    </Box>
  )
}

export default SetLogTagAndLevel
